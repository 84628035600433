<template>
  <div class="p-0 overflow-hidden">
    <!-- CUIDADO!!!!! NO BORRAR  -->
    <div ref="target" />
    <!-- <div ref="target">TARGET!!! MembersTable - {{ targetIsVisible }}</div> -->

    <AppScroll>
      <b-table
        v-if="showMembers"
        class="position-relative"
        :items="members2"
        :responsive="true"
        :small="isSmall"
        :fields="fields"
        :thead-class="!isDetailView ? 'd-none' : ''"
        :busy="busy"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: User -->
        <template #cell(fullname)="{ item }">
          <b-media class="d-flex align-items-center">
            <b-link :to="getMemberLocation(item)" class="text-dark d-flex ml-1">
              <div>
                <user-avatar :user="item" size="32" />
              </div>
              <div class="ml-1">
                <p class="font-weight-extrabold mb-0">
                  {{ `${item.name} ${item.surname}` }}
                </p>
                <small class="text-muted mb-0 html-text-ellipsis-1">
                  {{ item.headline || "--" }}
                </small>
              </div>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <!--template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template-->

        <!-- Column: Organizations -->
        <template #cell(organizations)="data">
          <div v-if="isOrganizationsAddonInstalled" >
            <div v-if="data.item.organizations && data.item.organizations.length > 0">
              <div v-for="(organization, index) in data.item.organizations" :key="index" class="mb-25">
                <p v-if="index < 3" class="mb-50">
                  <b-icon-building v-if="!isDetailView" class="align-middle text-body mr-50" />
                  <b-link :to="organizationLocation(organization)">
                    {{ typeof organization.name === 'object' ? translateTranslationTable(locale, organization.name ) : organization.name }}
                  </b-link>
                </p>
                <small v-if="index === 4" class="text-secondary font-weight-bold cursor-pointer" @click="showAllOrganizations(data.item)">
                  {{ $t("action.show-more") }} 
                </small>
              </div>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-end">
            <!-- 1:1 chat -->
            <b-button
              v-if="hasChatIndividualAddOn(item) && isChatAddonInstalled"
              v-b-tooltip.hover="{ boundary: 'viewport' }"
              pill
              size="sm"
              class="p-0"
              :title="$t('header.action.private-chat')"
              variant="link"
              @click="handleSidebarChange(true, item)"
            >
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-button>
            <!-- Create meeting -->
            <b-button
              v-if="hasMeetingAddOn(item) && isMeetingsAddonInstalled"
              v-b-tooltip.hover="{ boundary: 'viewport' }"
              pill
              size="sm"
              class="p-0 ml-1"
              :title="$t('header.action.request-meeting')"
              variant="link"
              @click="handleCalendarMeetingOpen(item)"
            >
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-button>
            <!-- Botones para abrir modal de conexiones -->
            <div v-if="isStaff">
              <b-button
                v-b-tooltip.hover
                pill
                class="p-0 ml-1"
                variant="link"
                :title="$t('members.generic-connections')"
                @click="selectMember(item)"
              >
                <b-icon-people size="16" class="align-middle text-body" />
              </b-button>
            </div>
          </div>
        </template>

        <!-- table loading -->
        <template #table-busy>
          <div class="text-center p-5">
            <b-spinner
              size="2rem"
              type="grow"
              class="d-block m-auto m-1"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <!-- table empty -->
        <template #empty>
          <div class="horizontal-placeholder">
            <div>
              <img :src="membersPlaceholder" class="d-block">
            </div>
            <div>
              <p class="text-primary text-center">
                {{ $t("available.message", { itemName: $t("members.title") }) }}
              </p>
            </div>
          </div>
        </template>
      </b-table>
      
      <b-row v-else class="horizontal-placeholder w-100">
        <b-col cols="12">
          <img :src="membersPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('addons.organizations.no-show-members', {type: 'members'}) }}
          </p>
        </b-col>
        <div v-if="currentCollectiveKey" class="d-block mx-auto customText ">
          <div class=" text-right ">
            <feather-icon
              v-if="canEdit && !isEditing"
              icon="Edit2Icon"
              size="18"
              style="cursor: pointer"
              class="text-primary ml-1 mb-50"
              role="button"
              @click="isEditing = true"
            />
            <feather-icon
              v-if="canEdit && isEditing"
              icon="SaveIcon"
              size="18"
              style="cursor: pointer"
              class="text-primary ml-1 mb-50"
              role="button"
              @click="saveAddon"
            />
          </div>
          <b-alert
            v-if="!isEditing"
            :show="!isEditing"
            variant="primary"
            class="pb-0"
          >
            <span v-html="customText" />
          </b-alert>
          <quill-editor
            v-else
            id="custom-text"
            v-model="customText"
            class="quillE"
          />
        </div>
      </b-row>
    </AppScroll>
    
    <template v-if="isRequestMeetingVisible">
      <teleport to="body">
        <RequestMeetingModal
          v-model="isRequestMeetingVisible"
          :member="memberForMeeting"
          :with-shared="false"
        />
      </teleport>
    </template>

    <!-- MODAL PARA AÑADIR MIEMBROS -->
    <b-modal
      v-if="isStaff && selectedMember"
      id="modal-create-connections"
      v-model="isConnectionModalVisible"
      :title="$t('members.relation-modal')"
      centered
      size="lg"
      hide-footer
    >
      <MemberAddConnectionsModal
        :is-table="true"
        :selected-member="selectedMember"
        @addConnection="addConnection"
        @restConnection="restConnection"
      />
    </b-modal>
    
    <!-- Member organizations -->
    <b-modal
      v-model="isOrganizationsModalVisible"
      :title="$t('members.member-organizations')"
      :ok-title="$t('form.actions.save')"
      hide-footer
    >
      <p class="font-weight-bolder mt-1 text-center">
        {{ currentMember.name }} {{ currentMember.surname }} <span v-if="currentMember.email">({{ currentMember.email }})</span>
      </p>
      <div
        v-for="(organization, index) in organizationsMember"
        :key="index"
        class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-25"
      >
        <p class="mb-50">
          <b-link :to="organizationLocation(organization)">
            {{ typeof organization.name === 'object' ? translateTranslationTable(locale, organization.name ) : organization.name }}
          </b-link>
        </p>
        <hr>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue';
import Teleport from 'vue2-teleport';
import Ripple from 'vue-ripple-directive';
// import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { CHAT_ACTIONS } from '@/views/apps/chat/constants/chat-store-constants';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import PeoplePlaceholder from '@/assets/images/placeholders/light/people.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import { quillEditor } from 'vue-quill-editor';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'MembersTable',

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    Teleport,
    UserAvatar,
    RequestMeetingModal: () => import ('../modals/RequestMeetingModal.vue'  /* webpackChunkName: "RequestMeetingModal" */),
    MemberAddConnectionsModal: () => import ('../modals/MemberAddConnectionsModal.vue'  /* webpackChunkName: "MemberAddConnectionsModal" */),
    AppScroll: () => import ('@core/components/app-scroll/AppScroll.vue'  /* webpackChunkName: "AppScroll" */),
    quillEditor
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
    noShowMembers: Boolean,
    isSearching: Boolean,
    filter: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isConnectionModalVisible: false,
      memberForMeeting: null,
      isRequestMeetingVisible: false,
      selectedMember: null,
      isRelationsLoading: false,
      lastLoadedPage: 1,
      noContact: false,
      noContactMessage: false,
      noCalendar: false,
      noCalendarMessage: false,
      members2: [],
      customNames: [],
      organizationsMember: [],
      currentMember: {},
      isOrganizationsModalVisible: false,
      isEditing: false,
      // customText: 'En esta agenda de profesionales deberás filtrar por país y por sector para acceder a los resultados de todos los que cumplen el criterio de búsqueda. Los datos han sido facilitados por las personas registradas en la comunidad y que han decidido estar visibles para el resto de usuarios.',

    };
  },
  computed: {
    customText () {
      if (this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].members.tagline) {
        return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].members.tagline)[0]
      }
      return 'En esta agenda de profesionales deberás filtrar por país y por sector para acceder a los resultados de todos los que cumplen el criterio de búsqueda. Los datos han sido facilitados por las personas registradas en la comunidad y que han decidido estar visibles para el resto de usuarios.'
    },
    canEdit() {
      return (
        this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'update',
          'member',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    showMembers() {
      return !this.noShowMembers || this.isSearching || this.filter;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    classifiers() {
      if (this.$store.getters.classifiers.members) {
        return this.$store.getters.classifiers.members.unpaginated;
      }
      return [];
    },
    customFields() {
      if (this.$store.getters.customFields.length) {
        return this.$store.getters.customFields;
      }
      return [];
    },
    isDetailView() {
      return this.$route.name === 'members' || this.$route.name === 'organization';
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    fields() {
      let fieldsArray = [{
        key: 'fullname',
        label: this.$t('user.title'),
        sortable: false,
        thClass: 'pl-2',
      }];
      //console.log('enabledOrganizationApp', this.enabledOrganizationApp);
      if(this.isOrganizationsAddonInstalled){
      // if(this.enabledOrganizationApp){
        fieldsArray.push({
          key: 'organizations',
          label: this.$t('members.edit.organizations.label'),
          sortable: false,
          thClass: 'pl-2',
        });
      }
      if(this.customFields?.length){
        for(let cField of this.customFields){
          fieldsArray.push({
            key: cField.key,
            label: this.translate(cField.name),
            sortable: false,
            thClass: 'pl-2',
          });
        }
      }
      // console.log('fieldsArray', fieldsArray);
      // fieldsArray.push({
      //     key: 'actions',
      //     label: this.$t('backoffice.members.actions'),
      //     sortable: false,
      //     thClass: 'pl-2',
      //   });
      return fieldsArray;
    },
    enabledOrganizationApp() {
      return (
        this.$store.getters.installedApps?.data?.filter(
          (item) => item.key === 'organizations',
        ).length > 0
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },

    /*     hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .meetingsSlots;
    }, */

    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    peoplePlaceholder() {
      return PeoplePlaceholder;
    },
    apps() {
      return Object.values(this.$store.getters.apps.apps);
    },
    isChatAddonInstalled() {
      //TODO: change by a setting in members app
      if(this.apps.length){
        return this.apps.filter(
          (item) => item.key === 'chat-individual',
        ).length > 0;
      }
      return this.$store.getters.installedApps?.data?.filter(
        (item) => item.key === 'chat-individual',
      ).length > 0;
    },
    isMeetingsAddonInstalled() {
      //TODO: change by a setting in members app
      if(this.apps.length){
        return this.apps.filter(
          (item) => item.key === 'meetings',
        ).length > 0;
      }
      return this.$store.getters.installedApps?.data?.filter(
        (item) => item.key === 'meetings',
      ).length > 0;
    },
    isOrganizationsAddonInstalled() {
      if(this.apps.length){
        return this.apps.filter(
          (item) => item.key === 'organizations',
        ).length > 0;
      }
      return this.$store.getters.installedApps?.data?.filter(
        (item) => item.key === 'organizations',
      ).length > 0;
    }
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! MembersTable.vue (targetIsVisible): ", newValue)
      if (newValue && !this.members2.length) {
        // console.log("2 --> WATCH!!! MembersTable.vue (targetIsVisible): ", newValue)
        this.loadAllData()
      }
    },

    locale() {
      //when change language, rebuild columns
      if (this.isDetailView) {
        this.createClassifiersColumns();
        this.createCustomFieldsColumns();
      }
    },
    isEditing(value) {
      return value;
    },
  },

  async created() {
    // console.log('this.classifiers', this.classifiers)
    // console.log('this.isDetailView', this.isDetailView)
    if (this.isDetailView && this.classifiers?.length <= 0) {
      await this.fetchClassifiers();
    }
    // console.log('isOrganizationsAddonInstalled', this.isOrganizationsAddonInstalled);
  //   console.log('CREATED!!! MembersTable.vue ')
  //   await this.fetchClassifiers();
  //   if (this.isDetailView) {
  //     this.createClassifiersColumns();
  //   } else {
  //     this.members2 = this.members.map((item) => item);
  //     this.pushActions();
  //   }
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  //   await this.getInstalledApps();
  //   // this.customText = Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].members.tagline)[0];
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    async loadAllData () {
      // console.log('loadAllData() MembersTable.vue ')
      if (this.isDetailView) {
        await this.fetchClassifiers();
        await this.fetchCustomFields();
        this.createClassifiersColumns();
        this.createCustomFieldsColumns();
      } else {
        this.members2 = this.members.map((item) => item);
        this.pushActions();
      }
      //console.log('this.members2', this.members2);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      if(!this.apps.length){
        await this.getInstalledApps();
      }
    },

    createClassifiersColumns() {
      if (this.classifiers && this.classifiers.length > 0) {
        let typeNames = this.classifiers.map((item) => item.typeName);
        typeNames = [...new Set(typeNames)];
        this.dataClassifiers();
        return typeNames.map((item, index) => {
          this.fields.push({
            key: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            label: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            sortable: false,
            thClass: 'pl-1',
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor }; font-weight: 600` }
                }
              });
              return style;
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale] : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              return value;
            },
          });
          this.createCustomFieldsColumns();
          if (index == this.classifiers.length - 1) {
            this.pushActions();
          }
        });
      }
      this.members2 = this.members.map((item) => item);
      const hasCustom = this.members2.some((item) => item.customFields && item.customFields.length > 0);
      if (hasCustom) {
        this.createCustomFieldsColumns();
      } else {
        this.pushActions();
      }
    },
    createCustomFieldsColumns() {
      this.members2.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
          item[i?.key] = i?.value;  //this.translate(i?.value);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      // this.customNames.map((item, index) => {
      //   this.fields.push({
      //     key: item,
      //     label: item,
      //     sortable: false,
      //     thClass: 'pl-50',
      //     formatter: (value) => {
      //       if (typeof value === 'boolean') {
      //         if (value === true) {
      //           return this.$t(
      //             'organizations.edit.custom-fields.boolean-response.true',
      //           );
      //         }
      //         if (value === false) {
      //           return this.$t(
      //             'organizations.edit.custom-fields.boolean-response.false',
      //           );
      //         }
      //       }
      //       return value;
      //     },
      //   });
      // });

      //Esto no lo entiendo:
      // if (!this.classifiers || this.classifiers.length === 0) {
      //   this.pushActions();
      // }
    },
    pushActions() {
      this.fields.push({
        key: 'actions',
        label: '',
        //label: this.$t('backoffice.members.actions'),
        sortable: false,
      });
    },
    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
    dataClassifiers() {
      this.members2 = this.members.map((item) => item);
      this.members.map((n, index) => {
        n.classifiers.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.members2[index][typeName])) {
                if (this.members2[index][typeName].includes(i.name)) {
                  return;
                }
                this.members2[index][typeName].push(i.name);
              } else {
                this.members2[index][typeName] = [];
                this.members2[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },
    dataFields() {
      this.members.map((n, index) => {
        n.customFields?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.members2[index][typeName] = i.value;
        });
      });
      //console.log('this.members2', this.members2);
    },
    hasMeetingAddOn(item) {
      if (this.currentUser.key === item.userKey && item.networking) {
        if (item.networking?.meetings === 0) {
          this.noCalendar = true;
          this.noCalendarMessage = this.$t(
            'header.action.your-meeting-disabled',
          );
        }
      } else if (item.networking == null) {
        return true;
      } else if (item.networking?.meetings === 0) {
        return false;
      } else {
        return true;
      }
    },
    hasChatIndividualAddOn(item) {
      if (this.currentUser.key === item.userKey && item.networking) {
        if (item.networking?.chat === 0) {
          this.noContact = true;
          this.noContactMessage = this.$t('header.action.your-chat-disabled');
        }
      } else if (item.networking == null) {
        return true;
      } else if (item.networking?.chat === 0) {
        return false;
      } else {
        return true;
      }
    },

    async getInstalledApps() {
      if (!this.$store.getters.installedApps.data?.length) {
        //console.log('getInstalledApps: ', this.$store.getters.installedApps)
        await this.$store.dispatch('getInstalledApps');
      }
    },

    handleCalendarMeetingOpen(member) {
      this.memberForMeeting = member;
      this.isRequestMeetingVisible = true;
    },
    // Abrir Modal y hacer fetch a los amigos
    async selectMember(firstMember) {
      this.selectedMember = firstMember;
      this.openConnectionModal();
    },
    openConnectionModal() {
      this.isConnectionModalVisible = !this.isConnectionModalVisible;
    },
    handleSidebarChange(isVisible, user) {
      if (isVisible) {
        const room = `${this.$store.getters.currentCollective.slug}-${
          parseInt(this.$store.getters.loggedMember.uniqueNumber)
          + parseInt(user.uniqueNumber)
        }`;
        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, { user, room });
      } else {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
    getMemberLocation(user) {
      if (!user.name) {
        return null;
      }

      const communityId = this.$route.params.slug ?? this.$route.params.communityId;

      return {
        name: 'member-details',
        params: {
          username: user.username,
          communityId: communityId,
        },
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    addConnection(member1, member2) {
      const indexMember1 = this.members.findIndex(
        (member) => member.key === member1,
      );
      this.members[indexMember1].relationsCount++;
      const indexMember2 = this.members.findIndex(
        (member) => member.key === member2,
      );
      this.members[indexMember2].relationsCount++;
    },
    restConnection(member1, member2) {
      const indexMember1 = this.members.findIndex(
        (member) => member.key === member1,
      );
      this.members[indexMember1].relationsCount--;
      const indexMember2 = this.members.findIndex(
        (member) => member.key === member2,
      );
      this.members[indexMember2].relationsCount--;
    },
    getImageResource,
    organizationLocation(organization) {
      return {
        name: 'organization',
        params: {
          slug: organization.slug,
          communityId: this.$route.params.communityId,
        },
      };
    },
    showAllOrganizations(item) {
      this.organizationsMember = this.memberOrganizations(item);
      this.currentMember = item;
      this.isOrganizationsModalVisible = true;
    },
    memberOrganizations(item) {
      if (this.members.length > 0) {
        return this.members?.filter((i) => i?.key === item.key)[0]?.organizations;
      }
      return item.organizations;
    },
    async fetchClassifiers() {
      // console.log('Fetching members classifiers...');
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: 'members',
        page: 1,
        requestConfig: { morphType: 'members', count: 1000 },
      });
    },
    async fetchCustomFields() {
      await this.$store.dispatch("fetchCustomFields", { 
        modelType: "member" 
      });
      // await this.$store.dispatch('getItems', {
      //   itemType: 'customfield',
      //   storedKey: 'members',
      //   page: 1,
      //   requestConfig: { morphType: 'members', count: 1000 },
      // });
    },
    translateTranslationTable,
    async saveAddon() {
      this.isEditing = false;
      try {
        await this.$store.dispatch('changeTaglineApp', {
          appID: 50,
          tagline: { es: this.customText },
        });

        this.notifySuccess(this.$t('integrations.messages.success'));
      } catch {
        this.notifyError(this.$t('integrations.messages.error'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.speaker-avatar__img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 13rem;
  border-radius: 1.2rem;
}
.speaker-card {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.speaker-avatar {
  margin: auto;
  display: block;
}
.disabled-button:hover {
  cursor: auto;
}
.disabled-icon {
  color: rgb(212, 212, 212);
}
.customText {
  max-width: 800px;
}
</style>
